<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    ref="popup"
  >
    <section class="flex justify-center items-center mt-8 mb-2 text-gray-500">
      <i class="text-4xl" :class="icon"></i>
    </section>

    <section class="px-4 text-2xl font-extrabold text-center">
      {{ title }}
    </section>

    <section class="px-4 font-bold text-center">
      {{ subtitle }}
    </section>

    <section class="px-4 my-6 text-center">
      {{ criticalityTitle }}
      <AppInput class="text-center" type="password" v-model="password" />
    </section>

    <section class="flex items-center py-4 px-4 mt-6 space-x-5 bg-gray-100">
      <anchor-button
        :variant="cancelButtonVariant"
        :class="`w-1/2`"
        @click="executeAction('cancel')"
      >
        {{ cancelButtonText }}
      </anchor-button>

      <anchor-button
        :variant="confirmButtonVariant"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="executeAction('confirm')"
      >
        {{ confirmButtonText }}
      </anchor-button>
    </section>
  </t-modal>
</template>

<script>
export default {
  name: 'ConfirmFirstModal',
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: [String, Number],
      required: false,
      default: 'fas fa-exclamation-circle',
    },
    title: {
      type: [String, Number],
      required: false,
      default: 'Are you sure?',
    },
    subtitle: {
      type: [String, Number],
      required: false,
      default: 'This action cannot be undone.',
    },
    cancelButtonText: {
      type: [String, Number],
      required: false,
      default: 'Cancel',
    },
    cancelButtonVariant: {
      type: String,
      required: false,
      default: 'primary',
    },
    confirmButtonText: {
      type: [String, Number],
      required: false,
      default: 'Continue',
    },
    confirmButtonVariant: {
      type: String,
      required: false,
      default: 'warning',
    },
    criticalityTitle: {
      type: [String],
      required: false,
      default: 'Type "CONFIRM" Please',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    password: null,
  }),
  watch: {
    id: {
      immediate: true,
      deep: false,
      handler(v) {
        console.log('confirm-modal-id', v)
      },
    },
  },

  methods: {
    executeAction(type) {
      if (type === 'confirm' && this.isLoading === false) {
        console.log('confirm', this.password)
        this.$emit('setPassword', this.password)
        this.password = null
        this.$emit('confirm')
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.password = null
        this.$refs.popup.hide()
        this.$emit('cancel')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
