<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    ref="popup"
  >
    <section class="px-4 text-2xl font-extrabold text-center mt-5">
      {{ $t('components.riderManagement.modal.notifyRider.headline') }}
    </section>
    <section class="px-4 ">
      <AppInput
        v-model="rider"
        type="text"
        :name="$t('components.riderManagement.modal.notifyRider.title.to')"
        rules=""
        :label="$t('components.riderManagement.modal.notifyRider.title.to')"
        :isInfoEnabled="false"
        disabled
      />
    </section>

    <section class="px-4">
      <AppInput
        v-model="pushOptions"
        type="richselect"
        :name="$t('components.riderManagement.modal.notifyRider.title.medium')"
        :label="$t('components.riderManagement.modal.notifyRider.title.medium')"
        :placeholder="
          $t('components.riderManagement.modal.notifyRider.placeholder.medium')
        "
        :options="mediumOptions"
        :isInfoEnabled="false"
        hide-search-box
        rules="required"
        multiple
      />
    </section>
    <section class="px-4" v-if="pushOptions.includes('email')">
      <AppInput
        v-model="title"
        type="text"
        :name="$t('components.riderManagement.modal.notifyRider.title.title')"
        rules=""
        :label="$t('components.riderManagement.modal.notifyRider.title.title')"
        :isInfoEnabled="false"
      />
    </section>
    <section class="px-4">
      <text-input
        :name="$t('components.riderManagement.modal.notifyRider.title.message')"
        :label="
          $t('components.riderManagement.modal.notifyRider.title.message')
        "
        type="textarea"
        input_type="textarea"
        :placeholder="
          $t('components.riderManagement.modal.notifyRider.placeholder.message')
        "
        v-model="message"
        rules="required"
      />
    </section>
    <section
      class="flex items-center justify-end px-4 py-4 mt-4 space-x-5 bg-gray-50"
    >
      <AppButton
        variant="primary"
        :class="`w-1/2`"
        @click="executeAction('cancel')"
      >
        {{ $t('components.stepNavigation.cancel') }}
      </AppButton>

      <AppButton
        variant="orange"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="executeAction('notify')"
      >
        {{ $t('components.stepNavigation.send') }}
      </AppButton>
    </section>
  </t-modal>
</template>

<script>
import TextInput from '@/components/form/TextInput.vue'
import { useEndpoints } from '@/composables'
export default {
  name: 'NotifyRider',
  components: { TextInput },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    riderData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    rider: '',
    title: '',
    message: '',
    pushOptions: [],
    queryStrings: '',
    mediumOptions: [
      {
        text: 'Push',
        value: 'push',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'SMS',
        value: 'sms',
      },
    ],
  }),
  watch: {
    riderData: {
      immediate: true,
      deep: true,
      handler(v) {
        this.rider = `${v.full_name} (${v.phone_number})`
        if (v.title) {
          this.title = v.title
        }
        if (v.message) {
          this.message = v.message
        }
      },
    },
  },

  methods: {
    async executeAction(type) {
      if (type === 'notify') {
        if (this.pushOptions.length > 0 && this.message) {
          this.isLoading = true

          const url = `${useEndpoints.rider.customNotifications()}${this.getQueryString(
            this.pushOptions
          )}`
          const data = new FormData()
          data.append('rider', this.riderData.id)
          if (this.pushOptions.includes('email')) {
            data.append('title', this.title)
          }
          data.append('message', this.message)

          await this.$http
            .post(url, data)
            .then(() => {
              this.$notify(
                {
                  group: 'generic',
                  type: 'success',
                  title: 'Success',
                  text: 'Notification has been sent successfully.',
                },
                3000
              )
              this.$refs.popup.hide()
              this.pushOptions = []
              this.$emit('refresh')
            })
            .catch((err) => {
              this.$notify(
                {
                  group: 'bottomRight',
                  type: 'error',
                  title: 'Failed',
                  text: `${err.response.data.message}`,
                },
                5000
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        } else {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'Fill up required information',
            },
            3000
          )
        }
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.$refs.popup.hide()
        this.pushOptions = []
        this.title = ''
        this.message = ''
      }
    },
    getQueryString(options) {
      const queryString = options.join('&')
      return '?' + queryString
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
